<template>
  <div class="popup-box GothamBook">
    <div class="popup-box-bg"></div>

      <!-- 内容 -->
        <div class="popup-box-box popup-box-box-width">
          
         

        
          <div class="popul-nftImg-img">
            <div class="lottie-style" v-show="signAsset==0">
              <div id="lottie1" class="lottie-style"></div>
            </div>
             <img
              src="../assets/img/sign_ok.svg"
              v-show="signAsset==1"
            />
            <img
              src="../assets/img/sign_not.svg"
             v-show="signAsset==2"
            />
            
          </div>
        
      <div class="popup-box-box-w">
        
        <div
          :class="['popup-box-title','GothamBook',centerTitle != undefined?'text-center' :'']"
          v-if="title != undefined && signAsset!=0"
        >{{title}}</div>

        
        <div
          :class="['popup-box-context',centerTitle != undefined?'text-center' :'']"
          v-if="connect != undefined&& signAsset!=0"
        >{{connect}}</div>

        
        <div v-if="nftImg != undefined">
          <div
            :class="['share-title','GothamBook',centerTitle != undefined?'text-center' :'']"
          >{{$t('lang_295')}}</div>

          <div class="share-list">
            
          </div>
        </div>
        <!-- 第一个输入框 -->
        <div v-if="inputOneText != undefined">
          <div class="popul-input-box">{{inputOneTitle}}</div>
          <div class="popul-input" v-if="inputOneText != undefined">
            <div class="popul-input-border1"></div>
            <div class="popul-input-border2"></div>
            <div class="popul-input-border3"></div>
            <div class="popul-input-border4"></div>
            <input
              class="inputmodel"
              type="text"
              v-model="oneInputText"
              @keyup="inputChange(2)"
              :placeholder="inputOneText"
              :disabled="inputOneDisabled!=undefined?inputOneDisabled:inputOneDisabled"
            />
            <div class="inputmodel-unit GothamBook" v-if="inputOneUnit != undefined">SCT</div>
          </div>
        </div>
        <!-- 第二个输入框 -->
        <div v-if="inputTwoText != undefined">
          <div class="popul-input-box">{{inputTwoTitle}}</div>
          <div class="popul-input">
            <div class="popul-input-border1"></div>
            <div class="popul-input-border2"></div>
            <div class="popul-input-border3"></div>
            <div class="popul-input-border4"></div>
            <input
              class="inputmodel"
              type="text"
              v-model="twoInputText"
              :placeholder="inputTwoText"
              @keyup="inputChange(2)"
            />
          </div>
        </div>

        <div class="buyMessage-box" v-if="Enterquantity!=undefined">
          <div class="buyMessage-top">{{$t('lang_283')}} {{Enterquantity}} {{$t('lang_326')}}</div>

          <div class="flex-x-between buyMessage-bottom" v-if="Currentsellingprice">
            <div>{{$t('lang_320')}}</div>
            <div class="buyMessage-bottom-r">{{oneInputText}} SCT</div>
          </div>

          <div class="flex-x-between buyMessage-bottom" v-if="sellingprice">
            <div>{{$t('lang_339')}}</div>
            <div class="buyMessage-bottom-r">{{sellingprice}}</div>
          </div>

          
        </div>
        <div class="sign-error" v-if="ChangeError!=undefined">{{ChangeError}}</div>

        <!-- 绿色按钮 -->
        <div
          :class="['marketPopup-out-rect-l','GothamBook',inputONStatus!=2?'marketPopup-out-rect-l-bBg':'marketPopup-out-rect-l-gBg']"
          v-if="gBtn != undefined"
          @click="gBtnSubmit(gBtnbackType)"
        >{{gBtn}}</div>

        <!-- Try again按钮 -->
        <div
          class="marketPopup-out-rect-l GothamBook marketPopup-out-rect-l-gBg"
          v-if="signError != undefined && signError != 0"
          @click="TryAgainSubmit(gBtnbackType)"
        >Try again</div>
        <!-- 黑色按钮 -->
        <div class="marketPopup-out-rect" @click="submit(0)">
          <div class="marketPopup-in-rect GothamBook">完成</div>
        </div>
        <div
          class="buyMessage-balance"
          v-if="Balance != undefined"
        >Balance {{$store.state.connect.balance}} SCT</div>
      </div>
    </div>
     </div>
</template>

<script>
import lottie from "lottie-web";
import animationData from "../assets/img/loading.json";
export default {
  name: "popup",
  components: {},
  props: [
    "gBtnbackType", //点击返回事件 "1" 721上架  "1155"上架
    "ico",
    "hrefurl",
    "title", //顶部标题
    "nftType", //nft类型
    "connect",
    "inputOneModel", //第一个输入框默认值
    "inputOneText", //第一个输入框
    "inputOneTitle",
    "inputOneUnit", //第一个输入框是否带单位
    "inputTwoModel", //第二个输入框默认值
    "inputTwoTitle",
    "inputTwoText", //第二个输入框
    "inputOneDisabled", //第一个输入框是否禁用
    "throttle",
    "back", //返回事件
    "nftImg", //nft图片
    "gBtn", //绿色button
    "gBtnback", //绿色button点击事件
    "bBtn", //黑色button
    "bBtnback", //黑色button点击事件
    "loading", //loading状态
    "sign", //签名
    "signError", //签名错误信息
    "ChangeError", //改价错误信息
    "signAsset", //资产签名状态 0 还没开始 1loading 2 完成
    "signPurchase", //购买签名状态 0 还没开始 1loading 2 完成
    "centerTitle",
    "Balance",
    "Enterquantity", //拥有数量 模块
    "Currentsellingprice", //上架设置价格 模块
    "sellingprice", //购买当前价格 模块
    "Servicefee", //手续费 模块
    "Youwillpay" //你将支付 模块
  ], //接收参数
  data() {
    return {
      inputONStatus: 2,
      inputdisabled: false,
      oneInputText: "",
      twoInputText: "",
      textuse: ""
    };
  },
  mounted() {
    this.svgFun()
    console.log("走");
  },
  destroyed() {
    lottie.destroy();
  },
  watch: {
    sign(newName, oldName) {
       this.svgFun();
    },
    inputOneModel(newName, oldName) {
      console.log("inputOneModel  newName=====");
      console.log(newName);
      if (this.inputOneModel != undefined) {
        this.oneInputText = "1";
      }
    },
    inputTwoModel(newName, oldName) {
      console.log("inputTwoModel  newName=====");
      console.log(newName);
      if (this.inputTwoModel != undefined) {
        this.twoInputText = "1";
      }
    }
    // ChangeError(newName, oldName) {
    //   console.log("ChangeError==========");
    //   console.log(newName);
    // }
  },
  methods: {
    
    submit: function(t) {
      console.log(t);
      this.back(t);
    },
    TryAgainSubmit: function(t) {
      var that = this;
      var data;
      data = { name: 99, againBtn: 3 };
      this.gBtnback(data);
      console.log(t);
    },
    gBtnSubmit: function(t) {
      var that = this;
      var data;
      //721上架
      if (t == 1) {
        if (that.oneInputText == "") {
          return;
        }
        data = { name: t, sellPrice: that.oneInputText };
      }
      //1155上架
      else if (t == 2) {
        data = { name: t };
      }
      //1155上架
      else if (t == 9) {
        if (that.oneInputText == "") {
          return;
        }
        if (that.twoInputText == "") {
          return;
        }
        data = {
          name: t,
          sellPrice: that.oneInputText,
          sellAmount: that.twoInputText
        };
      }
      //购买
      else if (t == 3) {
        var amount;
        if (parseInt(that.Enterquantity) < parseInt(that.oneInputText)) {
          that.ChangeError = "The quantity entered should not be greater than the quantity available for purchase";
          return;
        }
        const r = /^\+?[1-9][0-9]*$/; // 正整数
        // 如果判断不符合正则，则不是正整数不能提交
        if (!r.test(that.oneInputText)) {
          that.ChangeError = "Quantity must be a positive integer";
            return;
        }
        if (that.inputOneDisabled == undefined && that.oneInputText == "") {
          return;
        }
        if (that.inputOneDisabled != undefined) {
          amount = that.oneInputText;
        } else {
          amount = that.oneInputText;
        }
        data = {
          name: t,
          amount: amount
        };
      }
      //721转账
      else if (t == 5) {
        var amount;

        if (that.inputOneDisabled == undefined && that.oneInputText == "") {
          return;
        }
        if (that.twoInputText == "") {
          return;
        }
        if (that.inputOneDisabled != undefined) {
          amount = that.oneInputText;
        } else {
          amount = that.oneInputText;
        }
        data = {
          name: t,
          amount: amount,
          address: that.twoInputText
        };
      }
      //1155拥有再次购买
      else if (t == 7) {
        if (parseInt(that.Enterquantity) > parseInt(this.oneInputText)) {
          return;
        }
        data = {
          name: t
        };
      }
      //下架
      else if (t == 10) {
        data = {
          name: t
        };
      }
      //下架
      else if (t == 11) {
        data = {
          name: t,
          sellPrice: that.oneInputText
        };
      }
      this.gBtnback(data);
      console.log(t);
    },
    svgFun() {
      console.log('啊啊啊啊啊啊啊啊啊')
      let params1 = {
        container: document.getElementById("lottie1"),
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData
      };
      let anim1;
      anim1 = lottie.loadAnimation(params1);
      // anim.stop();//停止动画
      // anim.play();//回复动画
      // this.play?anim.play():anim.stop();
    },
  }
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.text-center {
  text-align: center;
}
.t40 {
  margin-top: 40px;
}
.b22 {
  margin-bottom: 22px;
}
.b40 {
  margin-bottom: 40px;
}
.popup-box-box-width {
  width: 358px;
}
/*--------------------------弹窗组件 公共的start-------------------------*/
.inputmodel-unit {
  font-size: 20px;
  font-weight: 400;
  padding: 6px;
}
.popup-sec-gif{
  /* z-index: 999; */
  position: absolute;
  height: calc(100% - 27px);
  width: 100%;
  overflow: hidden;
}
.popup-box {
  position: relative;
  width: 100%;
  text-align: center;
}
.popup-box-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: 999;
}
.popup-box-box {
  padding-left: 20px;
  padding-right: 20px;
  background: white;
  border-radius: 4px 4px 4px 4px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  box-sizing: border-box;
}
.popup-box-box-w {
  /* padding-top: 20px; */
  text-align: start;
}
.popul-input {
  position: relative;
  /* width: ; */
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popul-input-border1 {
  top: -1px;
  left: -1px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-left: 1px solid #979797;
  border-top: 1px solid #979797;
}

.popul-input-border2 {
  top: -1px;
  right: -1px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-right: 1px solid #979797;
  border-top: 1px solid #979797;
}

.popul-input-border3 {
  bottom: -1px;
  left: -1px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-left: 1px solid #979797;
  border-bottom: 1px solid #979797;
}

.popul-input-border4 {
  bottom: -1px;
  right: -1px;
  width: 10px;
  height: 10px;
  position: absolute;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
}
.tip-text {
  height: 20px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #53aeff;
  line-height: 16px;
  cursor: pointer;
}
.popup-btn-box {
  width: 140px;
  height: 42px;
  background: linear-gradient(90deg, #3682ff 0%, #3f27e5 100%);
  border-radius: 25px 25px 25px 25px;
  line-height: 42px;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 30px;
  cursor: pointer;
}
.inputmodel {
  text-align: center;
  height: 100%;
  width: calc(100% - 40px);
  margin: 0 auto;
  color: #cccccc;
  font-size: 24px;
}
.inputmodel::placeholder {
  text-align: center;
}
.reg-placeholder {
  font-size: 18px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #53aeff;
  line-height: 50px;
}

.popup-text {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #aaaaaa;
  line-height: 14px;
  margin: -14px 0 20px 0;
}

.popup-icon-title {
  width: 40px;
  margin-bottom: 16px;
  margin-top: -32px;
}

.popul-text {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #aaaaaa;
  line-height: 14px;
}
.popup-box-title {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
  font-size: 24px;
}
.popup-box-context {
  margin-top: 16px;
  margin-bottom: 24px;
  font-size: 16px;
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
  color: #a0a0a0;
}
.popul-input-box {
  margin-top: 24px;
  margin-bottom: 14px;
}
.marketPopup-out-rect-l {
  margin-top: 24px;
cursor: pointer;
  display: inline-block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  /* background: linear-gradient(135deg, #00ffee 0%, #00e35a 100%); */
  clip-path: polygon(
    0 0,
    calc(100% - 15px) 0,
    100% 15px,
    100% calc(100% + 15px),
    calc(100%) 100%,
    15px 100%,
    0 calc(100%),
    0 15px
  );
}
.marketPopup-out-rect-l-gBg {
  color: #000000;
  background: linear-gradient(135deg, #00ffee 0%, #00e35a 100%);
}
.marketPopup-out-rect-l-bBg {
  color: #ffffff;
  background: linear-gradient(135deg, #303030 100%, #303030 100%);
}
.marketPopup-out-rect {
  cursor: pointer;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 1px;
  background: linear-gradient(-135deg, transparent 10px, #979797 0) top right;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 31px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
    clip-path: polygon(
    0 0,
    calc(100% - 0px) 0,
    100% 0px,
    100% calc(100% + 0px),
    calc(100%) 100%,
    0px 100%,
    0 calc(100%),
    0 0px
  );
}
.marketPopup-in-rect {
  color: white;
  width: 100%;
  height: 100%;
  background: linear-gradient(-135deg, transparent 9.5px, #232323 0) top right;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.popul-nftImg {
  max-width: 300px;
  max-height: 300px;
  position: relative;
  padding-top: 40px;
  padding-bottom: 20px;
  /* background: red; */
  margin: 0 auto;
}

.popul-nftImg-img {
  padding-top: 30px;
  padding-bottom: 10px;
  color: #000000;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  text-align: center;
  font-size: 16px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  
}
.popul-nftImg-img img {
  width: 100px;
  height: 100px;
}
.popul-nftImg-position {
  position: absolute;
  width: 8px;
  height: 8px;
  transform: rotateZ(45deg);
  background: linear-gradient(0deg, #00ffee 0%, #00e35a 100%);
}
.popul-nftImg-position1 {
  left: 0;
  top: 0;
}
.popul-nftImg-position2 {
  right: 0;
  top: 0;
}
.popul-nftImg-position3 {
  left: 0;
  bottom: 0;
}
.popul-nftImg-position4 {
  right: 0;
  bottom: 0;
}
.share-list {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 24px;
  display: flex;
  cursor: pointer;
  justify-content: space-around;
  clip-path: polygon(
    0 0,
    calc(100% - 0px) 0,
    100% 0px,
    100% calc(100% + 0px),
    calc(100%) 100%,
    0px 100%,
    0 calc(100%),
    0 0px
  );
}
.share-title {
  margin-top: 24px;
  font-size: 18px;
}
.share-list img {
  width: 34px;
  height: 34px;
  /* margin-right: 24px; */
}
.close-style {
  cursor: pointer;
  margin: 24px 0 0 0;
}
@media only screen and (max-width: 700px) {
  .share-list {
    margin-top: 17px;
  }
  .popul-nftImg {
    max-width: 300px;
    max-height: 300px;
    width: 170px;
    height: 170px;
    min-width: 130px;
    min-height: 130px;
    position: relative;
    padding: 2px;
    /* background: red; */
    margin: 0 auto;
  }
  .marketPopup-out-rect-l {
    margin-top: 18px;

    display: inline-block;
    width: 100%;
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 16px;
    /* background: linear-gradient(135deg, #00ffee 0%, #00e35a 100%); */
    clip-path: polygon(
      0 0,
      calc(100% - 15px) 0,
      100% 15px,
      100% calc(100% + 15px),
      calc(100%) 100%,
      15px 100%,
      0 calc(100%),
      0 15px
    );
  }
  .close-style {
    cursor: pointer;
    margin: 6.4vw 0 0 0;
  }
  .popup-box-box-width {
    width: 287px;
  }

  .share-list img {
    width: 30px;
    height: 30px;
    /* margin-right: 24px; */
  }
  .popup-box-box {
    padding-left: 28px;
    padding-right: 28px;
  }
  .popup-box-context {
    margin-top: 10px;
    margin-bottom: 16px;
  }
  .share-title {
    margin-top: 17px;
    font-size: 12px;
  }

  
}

.sign-box {
  display: flex;
}
.sign-box-r {
  width: 236px;
  margin-left: 24px;
}
.sign-box-r-title {
  margin-bottom: 9px;
  font-size: 16px;
  color: white;
  font-weight: bold;
}
.lottie-style {
  width: 100px;
  height: 100px;
}

.sign-error {
  font-size: 13px;
  color: #c54242;
  margin-top: 24px;
  margin-bottom: 24px;
}

@import url(https://fonts.googleapis.com/css?family=Archivo+Narrow);
.cube-folding {
  width: 50px;
  height: 50px;
  display: inline-block;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  font-size: 0;
}
.cube-folding span {
  position: relative;
  width: 25px;
  height: 25px;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  transform: scale(1.1);
  display: inline-block;
}
.cube-folding span::before {
  content: "";
  background-color: #6ff7a5;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 25px;
  height: 25px;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  -webkit-animation: folding 2.5s infinite linear both;
  -moz-animation: folding 2.5s infinite linear both;
  animation: folding 2.5s infinite linear both;
}
.cube-folding .leaf2 {
  -webkit-transform: rotateZ(90deg) scale(1.1);
  -moz-transform: rotateZ(90deg) scale(1.1);
  transform: rotateZ(90deg) scale(1.1);
}
.cube-folding .leaf2::before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  background-color: #57f696;
}
.cube-folding .leaf3 {
  -webkit-transform: rotateZ(270deg) scale(1.1);
  -moz-transform: rotateZ(270deg) scale(1.1);
  transform: rotateZ(270deg) scale(1.1);
}
.cube-folding .leaf3::before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  background-color: #57f696;
}
.cube-folding .leaf4 {
  -webkit-transform: rotateZ(180deg) scale(1.1);
  -moz-transform: rotateZ(180deg) scale(1.1);
  transform: rotateZ(180deg) scale(1.1);
}
.cube-folding .leaf4::before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  background-color: #3ff487;
}

@-webkit-keyframes folding {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    -moz-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    -moz-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    -moz-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-moz-keyframes folding {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    -moz-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    -moz-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    -moz-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-ms-keyframes folding {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    -moz-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    -moz-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    -moz-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@keyframes folding {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    -moz-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    -moz-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    -moz-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
.cube-wrapper {
  margin: 0 auto;
  text-align: center;
}
.cube-wrapper:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  margin: auto;
  width: 90px;
  height: 6px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-filter: blur(2px);
  filter: blur(2px);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-animation: shadow 0.5s ease infinite alternate;
  -moz-animation: shadow 0.5s ease infinite alternate;
  animation: shadow 0.5s ease infinite alternate;
}
.cube-wrapper .loading {
  font-size: 12px;
  letter-spacing: 0.1em;
  display: block;
  color: #6ff7a5;
  position: relative;
  top: 25px;
  z-index: 2;
  -webkit-animation: text 0.5s ease infinite alternate;
  -moz-animation: text 0.5s ease infinite alternate;
  animation: text 0.5s ease infinite alternate;
}

@-webkit-keyframes text {
  100% {
    top: 35px;
  }
}
@-moz-keyframes text {
  100% {
    top: 35px;
  }
}
@-ms-keyframes text {
  100% {
    top: 35px;
  }
}
@keyframes text {
  100% {
    top: 35px;
  }
}
@-webkit-keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}
@-moz-keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}
@-ms-keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}
@keyframes shadow {
  100% {
    bottom: -18px;
    width: 100px;
  }
}
.made-with-love {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 10px;
  text-align: center;
  font-size: 10px;
  z-index: 9999;
  font-family: arial;
  color: #fff;
}
.made-with-love i {
  font-style: normal;
  color: #f50057;
  font-size: 14px;
  position: relative;
  top: 2px;
}
.made-with-love a {
  color: #fff;
  text-decoration: none;
}
.made-with-love a:hover {
  text-decoration: underline;
}
.market-text-center {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 40px;
  font-size: 32px;
}
.buyMessage-box {
  color: #a0a0a0;
  margin-top: 15px;
  margin-bottom: 40px;
}
.buyMessage-top {
  margin-bottom: 10px;
}
.buyMessage-bottom {
  margin-top: 14px;
}
.buyMessage-bottom-r {
  font-weight: 600;
  color: white;
}
.buyMessage-balance {
  color: #a0a0a0;
  text-align: center;
  margin-top: 13px;
  margin-bottom: 13px;
}

/*--------------------------弹窗组件 公共的end-------------------------*/
</style>
