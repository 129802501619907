<template>
  <div class="Faucet-box">
   <!-- 下架成功 -->
    <mpopup
      v-show="ShowAlert==1"
      title="Request Submitted"
      connect="The transfer is on the way. Toekns will be transferred to you in 1-2 minutes."
      :back="getbackPopup"
      :signAsset="signAsset"
    ></mpopup>
    <div class="Box-sc-1vpmd2a-0 gFZnrH">
      <div class="Box-sc-1vpmd2a-0 jtovnl">
        <div class="Box-sc-1vpmd2a-0 djpdOe">
          <div class="Box-sc-1vpmd2a-0 hokpsO">
            <div class="Box-sc-1vpmd2a-0 jEobDc">
              <div class="Box-sc-1vpmd2a-0 cDTLBZ"></div>
              <div class="Box-sc-1vpmd2a-0 kjcFsY"></div>
              <div class="Box-sc-1vpmd2a-0 qhkHr"></div>
              <div class="Box-sc-1vpmd2a-0 eRtMiV"></div>
              <div class="Box-sc-1vpmd2a-0 evEuDV"></div>
              <div class="Box-sc-1vpmd2a-0 cwSPsU"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Faucet-box-title">Request testnet META</div>
    <div
      class="Faucet-box-connect"
    >Get testnet META for an account on one of the supported blockchain testnets so you can create and test your own oracle and Chainlinked smart contract.</div>
    <div class="Faucet-box-position">
      <img src="../assets/img/logo.png" alt class="Faucet-box-position-logo" />
      <div class="Faucet-box-position-sort">
        <div class="Faucet-box-position-sort-title">Network</div>
        <div class="Faucet-sort-item-title" @click="showSortHide()">
          <div class="Faucet-sort-item-title-margin">{{questionList[questionDefault].question}}</div>
          <div id="triangle" :class="[showDetail?'bottom-arrow':'active']"></div>
          <div class="Faucet-sort-position" v-show="showDetail">
            <div
              v-for="(q,index) in questionList"
              :key="index"
              class="Faucet-sort-position-item"
              @click="SortTab(index)"
            >{{q.question}}</div>
          </div>
        </div>
        <!-- <div>123123</div> -->
      </div>
      <div class="h30"></div>
      <div class="Faucet-box-position-sort">
        <div class="Faucet-box-position-sort-title">Wallet address</div>
        <div class="Faucet-sort-item-title">
          <input
            class="indexinputmodel"
            type="text"
            v-model="oneInputText"
            @keyup="inputChange()"
            :placeholder="inputOneText"
            :disabled="inputOneDisabled!=undefined?inputOneDisabled:inputOneDisabled"
          />
        </div>
        <!-- <div>123123</div> -->
      </div>
       <!-- <div class="GothamBook" @click="setPopup(1)">测试按钮</div> -->
      <div class="h30"></div>
      <div class="h30"></div>
      <div :class="[inputOntap != false?'Faucet-sort-item-btn' :'Faucet-sort-item-btnFlase']" @click="submitTest()">Send me 10 testnet META</div>
    </div>
  </div>
</template>
<script>
import mpopup from "./Popup.vue";
import { ref, toRefs,computed, watch,reactive, onMounted,getCurrentInstance } from 'vue'
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const state=  reactive({
      signAsset: 0,
      oneInputText:'',
      inputOntap:false,
      showDetail: false,
      questionDefault: 0,
      ShowAlert:0,
      questionList: [
        // {
        //   question: "Ethereum Rinkeby",
        //   checked: true,
        //   chainId:4
        // },
        // {
        //   question: "Ethereum Ropsten",
        //   checked: true,
        //   chainId:3
        // },
        {
          question: "BSC Testnet",
          checked: false,
          chainId:97
        },
      ]
    })
    onMounted(async ()=>{
      // 组件挂载的过程
      //Load cached data 
      console.log(proxy.$http);
    })
    const submitTest= async function(){
      
       var that = this;
       
      var regExp3 = /^(0x)?[0-9a-f]{40}$/i; // 检测地址
      if (regExp3.test(that.oneInputText)) {
        that.ShowAlert=1;
        var params = {
          toAddress: that.oneInputText,
          chainId: that.questionList[that.questionDefault].chainId,
          tokens:'MST'
      };
      console.log(params);
          var res= await proxy.$http.postFromData('https://faucet.api.daoversal.com/faucet/api/trans',params)
          console.log(res);
          if(res.status==200){
            that.signAsset=1;
          }else{
            that.signAsset=2;
          }
      }
    }

        //输入框过滤
    const inputChange = function(){
      console.log('1111')
      var that = this;
      var regExp3 = /^(0x)?[0-9a-f]{40}$/i; // 检测地址
      
      if (regExp3.test(that.oneInputText)) {
        console.log('2222')
        that.inputOntap=true;
      }else{
        console.log('3333')
        that.inputOntap=false;
      }
    }

    const setPopup = function(t){
      console.log(t)
      this.ShowAlert = t;
      console.log('this.ShowAlert====='+this.ShowAlert);
    }

    return {
      ...toRefs(state),
      submitTest,
      inputChange,
      setPopup
    }
  },
  components: {
    mpopup,
  },
  methods: {
    async showSortHide(index) {
      var that = this;
      // await that.closeSortHide(index);

      that.showDetail = !that.showDetail;
      // setTimeout(function() {
      //   var pid = "citemPId" + index;
      //   var pdom = document.getElementById(pid);
      //   if (that.showDetail) {
      //     pdom.style.height = pdom.scrollHeight + "px";
      //   } else {
      //     pdom.style.height = 0;
      //   }
      // }, 1);
    },
    SortTab(index){
      this.questionDefault=index;
    },
    getbackPopup: function(t) {
      console.log("getbackPopup" + t);
      var that = this;
      that.ShowAlert = t;
      that.signAsset=0;
    },
  }
};
</script>
<style scoped>
@font-face {
  font-family: "Venera";
  src: url("../../src/assets/font/GothamBook.otf");
}
.h30 {
  height: 30px;
}
.Faucet-box {
  padding: 100px 0 0 5vw;
  position: relative;
  font-family: "Venera";
}
.Faucet-box-title {
  font-size: 38px;
  font-weight: bold;
}
.Faucet-box-connect {
  margin-top: 24px;
  color: var(--gray-700);
  line-height: 20px;
  font-weight: 600;
  max-width: 640px;
  margin-bottom: 0;
}
.Faucet-box-position {
  padding: 40px;
  position: absolute;
  width: 80vw;
  min-height: 200px;
  border: 1px solid #ccc;
  background: white;
  border-radius: 5px;
  top: 290px;
  left: 10vw;
}
.gFZnrH {
  margin: 0px;
  position: absolute;
  left: -200px;
  top: -100px;
  overflow: hidden;
  width: 90vw;
  height: 100vh;
  z-index: -999;
}
.jtovnl {
  max-width: 1440px;
  margin: 0px auto;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
}
.djpdOe {
  margin: 0px;
  position: absolute;
  top: -1180px;
  right: -156px;
  z-index: -1;
}
.hokpsO {
  margin: 0px;
  background-color: transparent;
  position: relative;
  height: 1870px;
  width: 1870px;
  mix-blend-mode: screen;
}
.jEobDc {
  margin: 0px;
  transform: rotateX(236deg) rotateZ(-135deg);
  width: 1100px;
  height: 1100px;
  position: absolute;
  transform-style: preserve-3d;
  top: 44.5%;
  left: 20%;
}
.jEobDc > div {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 17px;
  background-color: black;
  opacity: 0.02;
}
.cDTLBZ {
  margin: 0px;
}
.kjcFsY {
  margin: 0px;
  transform: translateZ(-1100px);
}
.qhkHr {
  margin: 0px;
  transform: rotateY(-90deg);
  transform-origin: right center;
}
.eRtMiV {
  margin: 0px;
  transform: rotateX(-90deg);
  transform-origin: center top;
}
.evEuDV {
  margin: 0px;
  transform: rotateY(90deg);
  transform-origin: left center;
}
.cwSPsU {
  margin: 0px;
  transform: rotateX(90deg);
  transform-origin: center bottom;
}
.Faucet-sort-item-title {
  position: relative;
  max-width: 400px;
  min-width: 120px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 700;
  height: 40px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.Faucet-sort-item-title:hover {
  border: 1px solid black;
}
#triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #a0a0a0;
}
.bottom-arrow {
  transform: rotate(0);
  /* transition: 0.5s; */
}
.active {
  transform: rotate(180deg);
  /* transition: 0.5s; */
}
.Faucet-sort-position {
  padding: 10px;
  min-width: 100px;
  top: 40px;
  right: 0;
  position: absolute;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;
  z-index: 999;
}
.Faucet-sort-position-item {
  font-size: 13px;
  height: 40px;
  line-height: 20px;
  background: white;
  border-radius: 5px;
  padding: 10px 5px 10px 5px;
}
.Faucet-sort-position-item:hover {
  background: rgba(0, 0, 0, 0.1);
}
.Faucet-box-position-sort-title {
  font-weight: bolder;
  margin-bottom: 20px;
}
.indexinputmodel {
  border: none;
  width: 360px;
    outline: none;
    /* width: 95%; */
    box-shadow: none;
}

.Faucet-sort-item-btn {
  width: 220px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 700;
  height: 50px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid black;
  border-radius: 5px;
}
.Faucet-sort-item-btnFlase {
  width: 220px;
  padding-left: 16px;
  padding-right: 16px;
  font-weight: 700;
  height: 50px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
  background:#979797;
  color:rgb(202, 199, 199);
  border: 1px solid #979797;
  border-radius: 5px;
}
.Faucet-box-position-logo {
  position: absolute;
  right: 150px;
  top: 10px;
  width: 350px;
}

@media only screen and (max-width: 1300px) {
  .Faucet-box-position-logo {
    right: 100px;
  }
}
@media only screen and (max-width: 1200px) {
  .Faucet-box-position-logo {
    right: 50px;
  }
}

@media only screen and (max-width: 1100px) {
  .Faucet-box-position-logo {
    right: 0px;
  }
}
@media only screen and (max-width: 1000px) {
  .Faucet-box-position-logo {
  display: none;
  
}
}

@media only screen and (max-width: 600px) {
  .indexinputmodel {
  width: 300px;
}
}

@media only screen and (max-width: 500px) {
  .indexinputmodel {
  width: 230px;
}

@media only screen and (max-width: 420px) {
  .indexinputmodel {
  width: 150px;
}
}
}

</style>